.background-dark {
  background-color: #1b222a;
}

.background-light {
  background-color: #1f2630;
}

.btn-primary {
  background-color: orange;
  border-width: 0px;
  font-weight: bold;
}

.logo {
  width: 100px;
  height: 100px;
}

.account-type {
  margin: 3px;
  padding: 5px;
  width: 60px;
  text-align: center;


}

.account-type-active {
  margin: 3px;
  padding: 5px;
  border-style: solid;
  border-radius: 2px;
  border-width: 0.1px;
  border-color: white;
  width: 60px;
  text-align: center;


}

first-block {
  display: flex;
  flex-direction: column
}

chart-block {
  flex: 8
}

order-place-block {
  flex: 4
}

second-block {
  display: flex;
  flex-direction: column
}



.account-type-main {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-bottom: 5px;
}
